import { Link } from 'gatsby'
import styled, { ThemeProvider } from 'styled-components'
import { styledKitMediaQueries } from 'styles'

import Div from 'components/Div'
import Content from 'components/Content'
import ThemeSwitch from 'components/ThemeSwitch'

type Props = {
  children: React.ReactNode
}

export default function Layout({ children, ...props }: Props) {
  return (
    <ThemeProvider theme={{ styledKitMediaQueries }}>
      <Content>
        <Div justifyBetween>
          <HomeLink to="/">robertkirsz</HomeLink>
          <ThemeSwitch />
        </Div>
      </Content>

      <Content {...props}>{children}</Content>
    </ThemeProvider>
  )
}

const HomeLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`
