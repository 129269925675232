import { useStaticQuery, graphql } from 'gatsby'

export default function useGitHubStars(repositoryName: string) {
  const data = useStaticQuery(graphql`
    query {
      stargazers {
        stargazers {
          repo
          stars
        }
      }
    }
  `)

  return data.stargazers.stargazers.find((item: { repo: string, stars: number }) => item.repo === repositoryName)?.stars
}
