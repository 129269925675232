import moment from 'moment'

import useGitHubStars from 'hooks/useGitHubStars'

import Div from 'components/Div'
import Hotjar from 'components/Hotjar'
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import Emoji from 'components/Emoji'
import GitHubLogo from 'components/icons/GitHubLogo'

function calculateDuration(date: string, secondDate?: string) {
  const months = moment(secondDate).diff(moment(date), 'months') + (secondDate ? 1 : 0)
  const years = Math.floor(months / 12)
  const rest = months % 12
  const message = []

  if (years) message.push(`${years} year${years > 1 ? 's' : ''}`)
  if (rest) message.push(`${rest} month${rest > 1 ? 's' : ''}`)

  return message.join(', ')
}

const webmaster =
  'I used to apply for "webmaster" positions in the past, back in the days when jQuery was considered "all we\'d ever need on frontend", and I still feel a strong nostalgia for that title.'

const whySoLong = `Why it took me seven years to get an engineer’s degree, when it usually takes four?

During later years, I started feeling that the value I get from attending studies is not worth the time and energy I spend on it. So I started thinking about getting some real work experience and after I've been granted discharge (it means I passed every exam and was ready to start working on my thesis) I decided to start working as a freelance instead.

I came back to finish my thesis a couple of years later only because I knew that'd make my mom happy.

I'm not aware of any situation where my degree was of any help to me.

These days, if someone asks me if they should go get a degree in IT if they want to be a programmer, I actively discourage them.`

const IntroSection = () => (
  <Div mobile={{ columnTop: 24 }} desktop={{ listLeft: 56 }}>
    <Div columnTop={16} flexNone>
      <h2>Robert Kirsz</h2>
      <h5>Frontend Developer</h5>
      <h6>Warsaw, Poland</h6>
      <a href="mailto:robert.kirsz@gmail.com">robert.kirsz@gmail.com</a>

      <Div listLeft mTop={32}>
        <a href="https://github.com/robertkirsz" target="_blank" rel="noreferrer noopener">
          GitHub
        </a>
        <span>|</span>
        <a href="https://www.linkedin.com/in/robertkirsz/" target="_blank" rel="noreferrer noopener">
          LinkedIn
        </a>
        <span>|</span>
        <a href="https://codepen.io/robertkirsz/" target="_blank" rel="noreferrer noopener">
          Codepen
        </a>
      </Div>
    </Div>

    <Div column css="a { font-weight: bold; }">
      <p>
        Frontend developer especially interested in working with{' '}
        <a href="https://en.wikipedia.org/wiki/User_interface" target="_blank" rel="noreferrer noopener">
          UI
        </a>{' '}
        and{' '}
        <a href="https://en.wikipedia.org/wiki/User_experience" target="_blank" rel="noreferrer noopener">
          UX
        </a>
        , creating rich, involving and accessible interfaces for single page applications (
        <a href="https://en.wikipedia.org/wiki/Single-page_application" target="_blank" rel="noopener noreferrer">
          SPA
        </a>
        ), progressive web apps (
        <a href="https://en.wikipedia.org/wiki/Progressive_web_application" target="_blank" rel="noopener noreferrer">
          PWA
        </a>
        ) and traditional websites.
      </p>
      <p>
        Developed fully functional, responsive applications based on business requirements, delivered in{' '}
        <strong>HTML, CSS and JavaScript</strong>, with{' '}
        <a href="https://reactjs.org/" target="_blank" rel="noreferrer noopener">
          React
        </a>{' '}
        being my UI library of choice (~{moment().to(moment('2015-11-01'), true)} of experience).
      </p>
      <p>
        Participated in projects in all stages of the product lifecycle, from the design to deployment. Gained
        experience in creating projects from scratch, as well as working with and maintaining existing codebases.
      </p>
      <p>
        I <Emoji>❤️</Emoji> CSS and everything UI-related. I often describe myself as a UI Developer, or (
        <abbr title={webmaster}>half-jokingly</abbr>) a Webmaster, even though I can build full-fledged, modern web apps
        just fine. I honestly don't remember the last time I got defeated by a piece of design that I couldn't recreate
        in code. Feel like challenging me?
      </p>
    </Div>
  </Div>
)

const ExperienceSection = () => (
  <Div columnTop={16}>
    <h2>Experience</h2>

    <h5 css="margin-top: 32px;">
      <a href="https://www.movingimage.com/" target="_blank" rel="noopener noreferrer">
        movingimage
      </a>
    </h5>
    <h6 css="margin-bottom: 4px;">Frontend Developer</h6>
    <small>February 2021 - now ({calculateDuration('2021-02-01')})</small>
    <p>Creating video stream and upload platform for Enterprise Customers. More info to come!</p>

    <h5 css="margin-top: 32px;">
      <a href="https://www.monedo.com/" target="_blank" rel="noopener noreferrer">
        Monedo
      </a>
    </h5>
    <h6 css="margin-bottom: 4px;">Frontend Developer</h6>
    <small>July 2017 - now ({calculateDuration('2017-07-17')})</small>
    <p>
      Creating frontend for Lending-as-a-Service (
      <a
        href="https://www.finextra.com/blogposting/16629/lending-as-a-service---next-generation-marketplace-loans"
        target="_blank"
        rel="noopener noreferrer"
      >
        LaaS
      </a>
      ) products in different variants for multiple markets (Spain, Poland, Russia, India). I was responsible for
      building the leads-acquiring part of the product, as well as creating and maintaining landing pages for the
      products and our company and creating a UI components library used by all frontend teams in the company.
      <br />
      <br />
      I'm currently working as a part-time maintainer of the frontend apps.
    </p>

    <h5 css="margin-top: 32px;">
      <a href="https://packhelp.com/" target="_blank" rel="noopener noreferrer">
        Packhelp
      </a>
    </h5>
    <h6 css="margin-bottom: 4px;">Frontend Developer</h6>
    <small>November 2020 - January 2021 ({calculateDuration('2020-11-01', '2021-01-01')})</small>
    <p>Coding React components for a 3D custom packaging editor.</p>

    <h5 css="margin-top: 32px;">
      <a href="https://www.mbank.pl/" target="_blank" rel="noopener noreferrer">
        mBank
      </a>
    </h5>
    <h6 css="margin-bottom: 4px;">Frontend Developer</h6>
    <small>August 2018 ({calculateDuration('2018-08-01', '2018-08-01')})</small>
    <p>
      Developing UI library in React for a new, secret product that the company wanted to release in collaboration with
      another big banking company, but the project got suddenly canceled at the end of the month that I joined... I
      decided not to stay in the company because then I'd be working with legacy jQuery apps.
    </p>

    <h5 css="margin-top: 32px;">MobileWeb Pro</h5>
    <h6 css="margin-bottom: 4px;">Frontend Developer</h6>
    <small>November 2015 - July 2017 ({calculateDuration('2015-11-01', '2017-07-01')})</small>
    <p>
      Creating web and native applications in React and React Native - from the ground up, as well as adding new
      functionalities to the existing ones. Working on projects for international clients from Germany, Belgium, Italy
      and the United States.
    </p>

    <h5 css="margin-top: 32px;">Black Tangerine Advertising Agency</h5>
    <h6 css="margin-bottom: 4px;">Webdesigner, Frontend Developer</h6>
    <small>September 2011 - December 2011 ({calculateDuration('2011-10-01', '2011-12-01')})</small>
    <p>
      Involved in designing advertising materials for clients (Bakoma and DecoMorreno among others), created a website
      for the agency.
    </p>

    <h5 css="margin-top: 32px;">Freelance Web Developer/Designer</h5>
    <small>July 2010 - November 2015 ({calculateDuration('2010-07-01', '2015-11-01')})</small>
    <p>
      Freelance web development and design - HTML, CSS, JavaScript (jQuery), PHP, MySQL, Photoshop, Illustrator,
      InDesign, Flash - landing pages, WordPress, custom CMSs, Flash animations, advertisement banners, print materials.
      You name it - I did it.
    </p>
  </Div>
)

const OpenSourceSection = () => {
  const useRippleStars = useGitHubStars('useripple')

  return (
    <Div columnTop>
      <h2>Open Source</h2>

      <Div listLeft mTop={32}>
        <Div as="a" href="https://github.com/robertkirsz/useripple" target="_blank" rel="noopener noreferrer" listLeft>
          <strong>useRipple</strong>
          <GitHubLogo />
        </Div>

        <a href="https://www.npmjs.com/package/useripple" target="_blank" rel="noopener noreferrer">
          <img src="https://img.shields.io/npm/v/useripple.svg?color=blue" alt="npm version" />
        </a>
      </Div>

      <p>
        A React Hook that adds an animated ripple effect to a clicked element. I'm so proud of all the {useRippleStars}{' '}
        stars it got on GitHub <Emoji>🌟</Emoji>!
      </p>

      <Div listLeft mTop={32}>
        <Div as="a" href="https://github.com/robertkirsz/useform" target="_blank" rel="noopener noreferrer" listLeft>
          <strong>useForm</strong>
          <GitHubLogo />
        </Div>

        <a href="https://www.npmjs.com/package/@robertkirsz/useform" target="_blank" rel="noopener noreferrer">
          <img src="https://img.shields.io/npm/v/@robertkirsz/useform.svg?color=blue" alt="npm version" />
        </a>
      </Div>

      <p>
        A React Hook for handling forms. I wrote this to repace redux-form that my last team and I were using in our
        projects. If you want an easy way to manage forms, I encourage you to give it a try!
      </p>

      <Div listLeft mTop={24}>
        <Div as="a" href="https://github.com/robertkirsz/styled-kit" target="_blank" rel="noopener noreferrer" listLeft>
          <strong>styled-kit</strong>
          <GitHubLogo />
        </Div>

        <a href="https://www.npmjs.com/package/styled-kit" target="_blank" rel="noopener noreferrer">
          <img src="https://img.shields.io/npm/v/styled-kit.svg?color=blue" alt="npm version" />
        </a>
      </Div>

      <p>
        Contains the Div component - an all-purpose wrapper element that makes it easy to position its children using
        flex attributes. It helped me and my team save a lot of time and lines of code. It also contains a
        Bootstrap-inspired grid system and a component for animating the height of other elements, but those are
        currently not yet documented.
      </p>

      <Div
        as="a"
        href="https://github.com/robertkirsz/lejzi"
        target="_blank"
        rel="noopener noreferrer"
        listLeft
        mTop={24}
      >
        <strong>lejzi</strong>
        <GitHubLogo />
      </Div>

      <p>My take on creating an image lazy-loading plugin. I'll update the README soon!</p>
    </Div>
  )
}

const GamesSection = () => (
  <Div columnTop>
    <h2>Games</h2>

    <p>I like to make a simple game once in a while. Here are some that are currently deployed:</p>

    <Div
      as="a"
      href="https://github.com/robertkirsz/social-distancing"
      target="_blank"
      rel="noopener noreferrer"
      listLeft
      mTop={24}
    >
      <strong>Social Distancing: The Game</strong>
      <GitHubLogo />
    </Div>

    <p>
      A game where you try to stay away from people that break social distance. A keyboard is required. My first project
      made in Svelte.
      <br />
      <a href="https://social-distancing.netlify.app/" target="_blank" rel="noopener noreferrer">
        Click here to play
      </a>
    </p>

    <Div
      as="a"
      href="https://github.com/robertkirsz/minesweeper"
      target="_blank"
      rel="noopener noreferrer"
      listLeft
      mTop={24}
    >
      <strong>Minesweeper</strong>
      <GitHubLogo />
    </Div>

    <p>
      A Minesweeper-type game created in JavaScript with jQuery. One of my first apps ever! I'm currently refactoring it
      and bringing it up-to-date.
      <br />
      <a href="https://robertkirsz-minesweeper.netlify.app/" target="_blank" rel="noopener noreferrer">
        Click here to play
      </a>
    </p>

    <Div
      as="a"
      href="https://github.com/robertkirsz/guitars"
      target="_blank"
      rel="noopener noreferrer"
      listLeft
      mTop={24}
    >
      <strong>Guitars</strong>
      <GitHubLogo />
    </Div>

    <p>
      An app I coded in 2013 as my IT engineer's degree project. It works okay in Chrome on desktop, so don't try to
      launch it in any other browser or on mobile device, okay?{' '}
      <a href="https://robertkirsz-guitars.netlify.app/" target="_blank" rel="noopener noreferrer">
        Click here
      </a>{' '}
      to check it out.
    </p>
  </Div>
)

const EducationSection = () => (
  <Div columnTop>
    <h2>Education</h2>

    <h5 css="margin: 32px 0 4px;">Upper School of Information Technology</h5>
    <small>
      Wyższa Szkoła Technologii Informatycznych - Warsaw 2006 - 2013 (<abbr title={whySoLong}>?</abbr>)
    </small>

    <Div as="p" columnTop={4} mTop={16}>
      Achieved Engineer’s Degree in Information Technology
      <br />
      <br />
      Specialization: Computer Graphics and DTP
      <br />
      <span>
        Thesis project:{' '}
        <a href="https://robertkirsz-guitars.netlify.app/" target="_blank" rel="noopener noreferrer">
          Creating multimedia web application in HTML5, CSS3 and JavaScript
        </a>
      </span>
    </Div>

    <h5 css="margin: 32px 0 4px;">Secondary School #79</h5>
    <small>Liceum Ogólnokształcące nr 79 - Warsaw 2003 - 2006</small>

    <p>Mathematics and Information Technology class profile</p>
  </Div>
)

const LanguagesSection = () => (
  <Div columnTop>
    <h2>Languages</h2>
    <p>Fluent English and native Polish.</p>
    <p>
      I speak and write/read quite well in both <Emoji>👍</Emoji>
    </p>
  </Div>
)

const SkillsSection = () => (
  <Div columnTop css="li { margin-bottom: 6px; }; a { font-weight: bold; }">
    <h2>Skills</h2>

    <h5 css="margin-top: 32px;">Soft</h5>

    {/* prettier-ignore */}
    <ul>
      <li>I love helping and sharing my knowledge with others. I can give concise hints, suggest more thorough solutions, or do complete and in-depth walkthroughs - whichever people prefer.</li>
      <li>I'm learning all the time and I'm not afraid of saying "I don't know" and asking questions, but before I do, I always try to find solutions on my own first.</li>
      <li>I know my place in the team - we are in this together, so you won't find me trying to compete with or outrun my teammates (but I'm open to some friendly challenges - <a href="http://codeinthedark.com/" target="_blank" rel="noopener noreferrer">Code in the Dark</a>, anyone?).</li>
      <li>I usually don't talk much. Most of the time, I sit quietly doing my tasks and let others do theirs. I don't try to correct people or force my opinions upon them, except when what they say is harmful or <span css="white-space: nowrap;"><Emoji>🐮</Emoji><Emoji>💩</Emoji></span> - then I speak up.</li>
    </ul>

    <h5 css="margin-top: 32px;">Technical</h5>

    {/* prettier-ignore */}
    <ul>
      <li>Real-life experience in building single page <a href="https://en.wikipedia.org/wiki/JavaScript" target="_blank" rel="noopener noreferrer">JavaScript</a> applications (<a href="https://en.wikipedia.org/wiki/Single-page_application" target="_blank" rel="noopener noreferrer">SPA</a>) in <a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">React</a> + <a href="https://redux.js.org/" target="_blank" rel="noopener noreferrer">Redux</a> + <a href="https://reactrouter.com/" target="_blank" rel="noopener noreferrer">React Router</a> using latest <a href="https://en.wikipedia.org/wiki/ECMAScript" target="_blank" rel="noopener noreferrer">ECMAScript</a> features</li>
      <li>In love with <a href="https://www.typescriptlang.org/" target="_blank" rel="noopener noreferrer">TypeScript</a></li>
      <li>Fluency in writing modular, maintainable, pixel-perfect <a href="https://developer.mozilla.org/en-US/docs/Web/CSS" target="_blank" rel="noopener noreferrer">CSS</a> (pure and using <a href="https://en.wikipedia.org/wiki/CSS-in-JS" target="_blank" rel="noopener noreferrer">CSS-in-JS</a>, with <a href="https://styled-components.com/" target="_blank" rel="noopener noreferrer">styled-components</a> being my favourite one)</li>
      <li>Coding fully responsive user interfaces (<a href="https://en.wikipedia.org/wiki/Responsive_web_design" target="_blank" rel="noopener noreferrer">RWD</a>) is a piece of (very tasty) cake for me</li>
      <li>Know-how to create custom build systems using <a href="https://webpack.js.org/" target="_blank" rel="noopener noreferrer">webpack</a> and <a href="https://babeljs.io/" target="_blank" rel="noopener noreferrer">Babel</a> (e.g. environment-dependant bundles)</li>
      <li>Solid knowledge of <a href="https://www.gatsbyjs.com/" target="_blank" rel="noopener noreferrer">Gatsby</a> static websites generator (this website's built on it)</li>
      <li>Hobby-level experience with creating progressive web applications (<a href="https://en.wikipedia.org/wiki/Progressive_web_application" target="_blank" rel="noopener noreferrer">PWA</a>) in <a href="https://svelte.dev/" target="_blank" rel="noopener noreferrer">Svelte</a> and <a href="https://vuejs.org/" target="_blank" rel="noopener noreferrer">Vue</a></li>
      <li>DOM manipulation with <a href="https://jquery.com/" target="_blank" rel="noopener noreferrer">jQuery</a> (in legacy code) and native JavaScript when dealing with simple apps</li>
      <li>Written lots of unit and integration tests using <a href="https://jestjs.io/" target="_blank" rel="noopener noreferrer">Jest</a> and <a href="https://testing-library.com/" target="_blank" rel="noopener noreferrer">Testing Library</a> and end-to-end test with <a href="https://www.cypress.io/" target="_blank" rel="noopener noreferrer">Cypress</a></li>
      <li>Knowledge of <a href="https://getbootstrap.com/" target="_blank" rel="noopener noreferrer">Bootstrap</a>, <a href="https://bulma.io/" target="_blank" rel="noopener noreferrer">Bulma</a>, <a href="http://getskeleton.com/" target="_blank" rel="noopener noreferrer">Skeleton</a>, <a href="https://material-ui.com/" target="_blank" rel="noopener noreferrer">Material UI</a>, <a href="https://tailwindcss.com/" target="_blank" rel="noopener noreferrer">tailwindcss</a> and similar frameworks (but I prefer building layouts from scratch using custom design systems)</li>
      <li>Experience with <a href="https://firebase.google.com/" target="_blank" rel="noopener noreferrer">Firebase</a> ecosystem</li>
      <li>Experience in working with <a href="https://en.wikipedia.org/wiki/Representational_state_transfer" target="_blank" rel="noopener noreferrer">REST</a> APIs</li> and <a href="https://graphql.org/" target="_blank" rel="noopener noreferrer">GraphQL</a>
      <li>Basic knowledge of <a href="https://nodejs.org/" target="_blank" rel="noopener noreferrer">Node</a> and <a href="https://expressjs.com/" target="_blank" rel="noopener noreferrer">Express</a> (can spin up a routing server for backend-mocking purposes) and WebSocket-based communication using <a href="https://socket.io/" target="_blank" rel="noopener noreferrer">Socket.IO</a></li>
      <li>Basics of creating native mobile apps with <a href="https://reactnative.dev/" target="_blank" rel="noopener noreferrer">React Native</a></li>
      <li>Familiarity with <a href="https://en.wikipedia.org/wiki/Scrum_(software_development)" target="_blank" rel="noopener noreferrer">Scrum</a> workflow</li>
      <li>High attention to writing clean, well formatted code using <a href="https://eslint.org/" target="_blank" rel="noopener noreferrer">ESLint</a> and <a href="https://prettier.io/" target="_blank" rel="noopener noreferrer">Prettier</a></li>
      <li>Gravitating towards <a href="https://en.wikipedia.org/wiki/Functional_programming" target="_blank" rel="noopener noreferrer">functional</a> and <a href="https://en.wikipedia.org/wiki/Declarative_programming" target="_blank" rel="noopener noreferrer">declarative</a> programming</li>
      <li>Knowledge of <a href="https://git-scm.com/" target="_blank" rel="noopener noreferrer">Git</a> as a version control system</li>
      <li>Ease of use of <a href="https://www.adobe.com/products/photoshop.html" target="_blank" rel="noopener noreferrer">Photoshop</a> for converting PSD files to code</li>
      <li>Basic knowledge of <a href="https://www.sketch.com/" target="_blank" rel="noopener noreferrer">Sketch</a></li>
      <li>I can work on <a href="https://en.wikipedia.org/wiki/MacOS" target="_blank" rel="noopener noreferrer">macOS</a> (favourite), <a href="https://en.wikipedia.org/wiki/Microsoft_Windows" target="_blank" rel="noopener noreferrer">Windows</a> or <a href="https://en.wikipedia.org/wiki/Linux" target="_blank" rel="noopener noreferrer">Linux</a></li>
      <li>Basic knowledge of <a href="https://gruntjs.com/" target="_blank" rel="noopener noreferrer">Grunt</a> and <a href="https://gulpjs.com/" target="_blank" rel="noopener noreferrer">gulp</a> task runners (but I prefer getting things done with <a href="https://webpack.js.org/" target="_blank" rel="noopener noreferrer">webpack</a>)</li>
      <li>Familiarity with <a href="https://trello.com/en" target="_blank" rel="noopener noreferrer">Trello</a>, <a href="https://www.scrumdo.com/" target="_blank" rel="noopener noreferrer">ScrumDo</a> and <a href="https://www.atlassian.com/software/jira" target="_blank" rel="noopener noreferrer">Jira</a> software</li>
      <li>Experience in delivering both live and online demos and product presentations in Polish and English</li>
    </ul>
  </Div>
)

const FriendsSection = () => (
  <Div column css="li { margin-bottom: 6px; }">
    <h2>Friends</h2>

    <p css="margin-top: 32px;">
      These are amazing people I meet down the road that left a significant imprint on my life. I genuinely consider
      myself a better person thanks to them. If you want someone exceptional in your team, reach out to them:
    </p>

    {/* prettier-ignore */}
    <ul css="a { text-decoration: none; } span { text-decoration: underline; }">
      <li><a href="https://www.linkedin.com/in/marzenamalachowska/" target="_blank" rel="noopener noreferrer"><span>Marzena Małachowska</span> - Frontend Developer</a></li>
      <li><a href="https://www.linkedin.com/in/kzadurska/" target="_blank" rel="noopener noreferrer"><span>Kasia Zadurska</span> - Frontend Developer</a></li>
      <li><a href="https://www.linkedin.com/in/basiakazmierczak-a57446ab/" target="_blank" rel="noopener noreferrer"><span>Basia Kaźmierczak-Lubelska</span> - HR Manager</a></li>
      <li><a href="https://www.linkedin.com/in/iwonasikora/" target="_blank" rel="noopener noreferrer"><span>Iwona Sikora</span> - UX/UI Designer</a></li>
      <li><a href="https://www.linkedin.com/in/rafalcholaj/" target="_blank" rel="noopener noreferrer"><span>Rafał Chołaj</span> - Product Owner</a></li>
      <li><a href="https://www.linkedin.com/in/kubarodzen/" target="_blank" rel="noopener noreferrer"><span>Kuba Rodzeń</span> - Java Developer</a></li>
      <li><a href="https://www.linkedin.com/in/kowalikm/" target="_blank" rel="noopener noreferrer"><span>Marcin Kowalik</span> - Java Developer</a></li>
      <li><a href="https://www.linkedin.com/in/radek-starczynowski-at-gmail-com/" target="_blank" rel="noopener noreferrer"><span>Radek Starczynowski</span> - Java Developer</a></li>
      <li><a href="https://www.linkedin.com/in/marek-siemieniuk-morawski/" target="_blank" rel="noopener noreferrer"><span>Marek Siemieniuk-Morawski</span> - QA Engineer</a></li>
      <li><a href="https://www.linkedin.com/in/ryan-scoville-20677599/" target="_blank" rel="noopener noreferrer"><span>Ryan Scoville</span> - Chief Technology Officer</a></li>
    </ul>
  </Div>
)

export default function HireMePage() {
  return (
    <>
      <SEO title="Hire me" />
      <Hotjar />

      <Layout columnTop={56}>
        <IntroSection />

        <Div mobile={{ columnTop: 56 }} desktop={{ listLeft: 40 }}>
          <Div columnTop={56} flex={4}>
            <ExperienceSection />
            <OpenSourceSection />
            <GamesSection />
            <EducationSection />
            <LanguagesSection />
          </Div>

          <Div columnTop={56} flex={3}>
            <SkillsSection />
            <FriendsSection />
          </Div>
        </Div>
      </Layout>
    </>
  )
}
