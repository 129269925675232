import { ThemeToggler } from 'gatsby-plugin-dark-mode'

export default function ThemeSwitch(props) {
  return (
    <ThemeToggler>
      {({ theme, toggleTheme }) => (
        <button onClick={() => toggleTheme(theme === 'dark' ? 'light' : 'dark')} {...props}>
          {theme === 'dark' ? 'light' : 'dark'} mode
        </button>
      )}
    </ThemeToggler>
  )
}
